<script setup lang="ts">
import { PublicKey } from "@solana/web3.js";
import { useReferralRewards } from "~/services/queries/use-referral-rewards.ts";
import { useSonicRewards } from "~/services/queries/use-sonic-rewards";
import { useSonicRewardsInfo } from "~/services/queries/use-sonic-rewards-info";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import {
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipRoot,
  TooltipTrigger,
} from "radix-vue";
import { mitt } from "~/lib/events";

const referralRewardsQuery = useReferralRewards();

const totalRewardsValue = computed(
  () => referralRewardsQuery.data?.value?.rewards?.total_usd || 0
);

const isOpen = ref(false);

const totalRewards = computed(() =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(totalRewardsValue.value)
);

const level1RewardsValue = computed(
  () => referralRewardsQuery.data?.value?.rewards?.level_one_rewards || 0
);

const level1Rewards = computed(() =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(level1RewardsValue.value)
);

const level2RewardsValue = computed(
  () => referralRewardsQuery.data?.value?.rewards?.level_two_rewards || 0
);

const level2Rewards = computed(() =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(level2RewardsValue.value)
);

// Rewards Tokens
const rewardsTokens = computed(
  () =>
    [
      {
        symbol: "SOL",
        name: "Solana",
        mint: new PublicKey("So11111111111111111111111111111111111111112"),
        icon: "/img/sol.svg",
        amount: referralRewardsQuery.data?.value?.rewards?.reward_sol || 0,
      },
      {
        symbol: "USDT",
        name: "Tether",
        mint: new PublicKey("Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"),
        icon: "/img/usdt.svg",
        amount: referralRewardsQuery.data?.value?.rewards?.reward_usdt || 0,
      },
      {
        symbol: "USDC",
        name: "USD Coin",
        mint: new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"),
        icon: "/img/usdc.svg",
        amount: referralRewardsQuery.data?.value?.rewards?.reward_udsc || 0,
      },
    ] as const
);

const formattedRewardsTokens = computed(() => {
  return rewardsTokens.value.map((token) => {
    return {
      ...token,
      amount: Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(token.amount),
    };
  });
});

// Sonic Rewards
const sonicRewardsQuery = useSonicRewards();
const $veTokenRewardsAmount = computed(() =>
  Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(sonicRewardsQuery.data?.value?.total_sonic_rewards || 0)
);
const sonicRewardsInfoDetails = computed(() => {
  let info = sonicRewardsQuery.data?.value?.sonic_referrals_detail || [];
  // if (info.length === 0) {
  //   info = [
  //     {
  //       tier: 1,
  //       referrals: 10,
  //     },
  //     {
  //       tier: 2,
  //       referrals: 20,
  //     },
  //     {
  //       tier: 3,
  //       referrals: 30,
  //     },
  //     // Add 10 more
  //     {
  //       tier: 4,
  //       referrals: 40,
  //     },
  //     {
  //       tier: 5,
  //       referrals: 50,
  //     },
  //     {
  //       tier: 6,
  //       referrals: 60,
  //     },
  //   ];
  // }
  return info;
});
const formattedSonicRewardsInfo = computed(() => {
  return sonicRewardsInfoDetails.value
    .map((referral) => {
      return {
        tier: referral.tier,
        tierName: `Tier ${referral.tier}`,
        referrals: referral.referrals,
      };
    })
    .slice(0, 3);
});

const sonicRewardsInfoQuery = useSonicRewardsInfo();

const $veTokenRewardsDistribution = computed(() => ({
  total_number: sonicRewardsInfoQuery.data?.value?.total_daily_rewards || 0,
  distributed_number:
    sonicRewardsInfoQuery.data?.value?.distributed_rewards || 0,
  total: Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(sonicRewardsInfoQuery.data?.value?.total_daily_rewards || 0),
  distributed: Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(sonicRewardsInfoQuery.data?.value?.distributed_rewards || 0),
}));

function handleClickFullHistory() {
  mitt.emit("referral-history-dialog:open");
}
</script>

<template>
  <div
    class="flex flex-col items-start justify-start gap-8 w-full font-sub border border-white/20 rounded-lg py-4 px-3 sm:px-6 sm:py-6"
  >
    <div class="flex items-center justify-start gap-2">
      <div class="text-base sm:text-2xl font-bold font-sans">
        Cashback Rewards
      </div>
    </div>

    <!-- Total Rewards -->
    <div
      class="flex flex-col gap-0.5 w-full bg-[#101010] p-7 relative overflow-hidden relative"
    >
      <div class="text-base text-sonic-yellow font-bold z-10">
        Total Rewards
      </div>
      <div class="text-[40px] font-bold z-10">
        {{ totalRewards
        }}<span class="text-xl ml-0.5 font- leading-normal">USD</span>
      </div>
      <IconsDollar
        class="w-1/2 z-0 h-[320px] text-[#151515] absolute right-0 rotate-[-2.143deg] top-1/2 -translate-y-1/2"
      />
    </div>

    <!-- Rewards Details -->
    <div class="flex flex-col gap-3 w-full relative">
      <div
        class="text-[10px] sm:text-xs text-sonic-gray flex justify-between items-center"
      >
        <div class="flex items-center">
          Rewards Details &nbsp;
          <TooltipProvider :delay-duration="100">
            <TooltipRoot>
              <TooltipTrigger>
                <IconsInfo class="w-4 h-4" />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent
                  class="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade max-w-[240px] font-sub text-xs bg-[#151519] border border-[#27282D] text-white rounded-md p-3 z-10"
                  :side-offset="5"
                >
                  The displayed USD value is based on the token price at the
                  transaction time, not the real-time exchange rate.
                  <TooltipArrow class="fill-[#151519]" :width="8" />
                </TooltipContent>
              </TooltipPortal>
            </TooltipRoot>
          </TooltipProvider>
        </div>
        <div>Referral Amount</div>
      </div>
      <div class="flex flex-col space-y-4">
        <!-- L1 -->
        <div
          class="flex justify-between items-center border-b border-white/20 pb-4"
        >
          <div class="grid grid-cols-[auto_auto] gap-2">
            <IconsUserPlus class="w-7 h-7 sm:w-8 sm:h-8" />
            <div class="flex flex-col justify-start items-start">
              <div class="text-xs sm:text-base text-white">Direct Rewards</div>
              <div class="text-xs sm:text-sm text-sonic-disabled">
                10% cashback
              </div>
            </div>
          </div>
          <div class="text-white text-sm sm:text-lg">
            {{ level1Rewards }}
          </div>
        </div>
        <!-- L2 -->
        <div
          class="flex justify-between items-center border-b border-white/20 pb-4"
        >
          <div class="grid grid-cols-[auto_auto] gap-2">
            <IconsUsersPlus class="w-7 h-7 sm:w-8 sm:h-8" />
            <div class="flex flex-col justify-start items-start">
              <div class="text-xs sm:text-base text-white">Network Rewards</div>
              <div class="text-xs sm:text-sm text-sonic-disabled">
                5% cashback
              </div>
            </div>
          </div>
          <div class="text-white text-sm sm:text-lg">
            {{ level2Rewards }}
          </div>
        </div>
      </div>
    </div>

    <Collapsible
      v-model:open="isOpen"
      class="w-full flex flex-col gap-2 items-center"
    >
      <Button
        size="icon"
        class="mx-auto"
        variant="ghost"
        @click="isOpen = !isOpen"
      >
        <IconsArrowDown class="w-6 h-6 text-white/30" />
      </Button>
      <CollapsibleContent class="w-full flex flex-col gap-4">
        <div class="flex flex-col gap-3 w-full">
          <div
            class="text-[10px] sm:text-xs text-sonic-gray flex justify-between items-center"
          >
            <div>Token Details</div>
            <div>Amount</div>
          </div>
          <div class="flex flex-col space-y-4">
            <!-- tokens -->

            <div
              class="flex justify-between items-center not-last:border-b not-last:border-white/20 not-last:pb-4"
              v-for="token in formattedRewardsTokens"
              :key="token.symbol"
            >
              <div class="grid grid-cols-[auto_auto] gap-2">
                <img
                  :src="token.icon"
                  :alt="token.symbol"
                  class="w-7 h-7 rounded-full overflow-hidden"
                />
                <div class="flex flex-col justify-start items-start">
                  <div class="text-xs sm:text-sm text-white font-semibold">
                    {{ token.symbol }}
                  </div>
                  <div class="text-xs sm:text-sm text-sonic-disabled">
                    {{ token.name }}
                  </div>
                </div>
              </div>
              <div class="text-white text-sm sm:text-lg">
                {{ token.amount }}
              </div>
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>

    <div />

    <!-- 
    
      SONIC REWARDS

    -->

    <div class="flex items-center justify-start gap-2">
      <div class="text-base sm:text-2xl font-bold font-sans">Sonic Rewards</div>
    </div>

    <div
      class="flex flex-col gap-0.5 w-full bg-[#101010] p-7 relative overflow-hidden"
    >
      <div class="text-base text-sonic-yellow font-bold z-10">
        $veToken Amount
      </div>
      <div class="text-[40px] font-bold z-10">
        {{ $veTokenRewardsAmount }}
      </div>
      <IconsSonic
        class="w-1/2 z-0 h-[320px] text-[#151515] absolute right-0 rotate-[-2.143deg] top-1/2 -translate-y-1/2"
      />
    </div>

    <!-- Activated Details -->
    <template v-if="formattedSonicRewardsInfo.length > 0">
      <div class="flex flex-col gap-3 w-full">
        <TooltipProvider :delay-duration="100">
          <div
            class="text-[10px] sm:text-xs text-sonic-gray flex justify-between items-center"
          >
            <div class="flex items-center">
              Activated Details &nbsp;
              <TooltipRoot>
                <TooltipTrigger>
                  <IconsInfo class="w-4 h-4" />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent
                    class="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade max-w-[240px] font-sub text-xs bg-[#151519] border border-[#27282D] text-white rounded-md p-3 z-10"
                    :side-offset="5"
                  >
                    Displayed numbers only reflect successful referrals that
                    received Sonic rewards due to the daily cap on $veToken
                    distribution. Today's Remaining $veToken:
                    {{ $veTokenRewardsDistribution.distributed }}/
                    {{ $veTokenRewardsDistribution.total }}
                    <TooltipArrow class="fill-[#151519]" :width="8" />
                  </TooltipContent>
                </TooltipPortal>
              </TooltipRoot>
            </div>
            <div>Amount</div>
          </div>
        </TooltipProvider>
        <div class="flex flex-col space-y-4">
          <!-- Iterate over all tier rewards -->
          <div
            class="flex justify-between items-center border-b border-white/20 pb-4"
            v-for="tier in formattedSonicRewardsInfo"
            :key="tier.tier"
          >
            <div class="grid grid-cols-[auto_auto] gap-2">
              <div class="flex flex-col justify-start items-start">
                <div class="text-xs sm:text-base text-white">
                  {{ tier.tierName }}
                </div>
              </div>
            </div>
            <div class="text-white text-sm sm:text-lg">
              {{ tier.referrals }}
            </div>
          </div>
        </div>

        <div
          class="flex justify-center text-base text-sonic-lightblue"
          @click="handleClickFullHistory"
        >
          Full History
        </div>
      </div>
    </template>
  </div>
  <LazyMoleculesReferralsRewardsHistoryDialog />
</template>
