<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M16.5 20.5333L8.5 12.5333L10.3667 10.6667L16.5 16.8L22.6333 10.6667L24.5 12.5333L16.5 20.5333Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
