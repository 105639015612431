<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M2 12.8333V11.5H3.33333V12.8333H2ZM2 10.5V9.16665H3.33333V10.5H2ZM2 8.16665V6.83331H3.33333V8.16665H2ZM4.33333 15.1666V13.8333H5.66667V15.1666H4.33333ZM4.66667 12.5V1.83331H13.3333V12.5H4.66667ZM6 11.1666H12V3.16665H6V11.1666ZM6.66667 15.1666V13.8333H8V15.1666H6.66667ZM2 15.1666V13.8333H3.33333V15.1666H2ZM9 15.1666V13.8333H10.3333V15.1666H9ZM2 5.83331V4.49998H3.33333V5.83331H2Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
