<script setup lang="ts">
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

const item1List = ref([
  "Cashback Rewards: Earned in the same currency used by the referred buyer, e.g., USDT/SOL.",
  "Sonic Rewards: Earned in $veToken, a non-transferable token exchangeable 1:1 with $Token.",
]);
const item2List = ref([
  "Copy your invitation code/link to social media.",
  "Earn +10% cashback on your invites' spending and additional +5% cahsback on your invite's invites.",
  'Rewards are automatically distributed via the smart contract. Check more details <a href="https://hyperfusedocs.sonic.game/rewards-and-contributions/rewards-for-hyperfuse-guardian-node-participants/node-referral-rewards" target="_blank" class="text-sonic-lightblue hover:underline focus:outline-none decoration-dashed underline-offset-2">here</a>.',
]);
const item4List = ref([
  "Copy your invitation code/link to social media.",
  "Earn $veToken rewards by successfully referring new purchasers.",
  "Rewards are distributed on a first-come, first-served basis, with a daily limit of 33,333 $veToken.",
  "$veToken can be claimed once the Node is ready for deployment.",
]);
</script>

<template>
  <Accordion
    type="multiple"
    class="w-full font-sub"
    :default-value="['1', '2']"
  >
    <AccordionItem value="1" class="border-none">
      <AccordionTrigger
        class="!no-underline font-bold justify-start gap-2 text-start"
      >
        What rewards are available for referrals?
      </AccordionTrigger>
      <AccordionContent class="text-sonic-disabled border-none">
        <ol class="list-decimal list-outside pl-4 flex flex-col gap-2">
          <li v-for="(item, index) in item1List" :key="index">
            {{ item }}
          </li>
        </ol>
      </AccordionContent>
    </AccordionItem>
    <AccordionItem value="2" class="border-none">
      <AccordionTrigger
        class="!no-underline font-bold justify-start gap-2 text-start"
      >
        How do I earn my cashback rewards?
      </AccordionTrigger>
      <AccordionContent class="text-sonic-disabled border-none">
        <ol class="list-decimal list-outside pl-4 flex flex-col gap-2">
          <li
            v-for="(item, index) in item2List"
            :key="index"
            v-html="item"
          ></li>
        </ol>
      </AccordionContent>
    </AccordionItem>
    <AccordionItem value="3" class="border-none">
      <AccordionTrigger
        class="!no-underline font-bold justify-start gap-2 text-start"
      >
        How to calculate cashback rewards?
      </AccordionTrigger>
      <AccordionContent class="text-sonic-disabled border-none">
        Users will earn +10% on top of any amount spent by their invites(direct
        rewards), and +5% on top of any amount spent by their invite's
        invites(network rewards).
      </AccordionContent>
    </AccordionItem>
    <AccordionItem value="4" class="border-none">
      <AccordionTrigger
        class="!no-underline font-bold justify-start gap-2 text-start"
      >
        How do I earn my Sonic rewards?
      </AccordionTrigger>
      <AccordionContent class="text-sonic-disabled border-none">
        <ol class="list-decimal list-outside pl-4 flex flex-col gap-2">
          <li v-for="(item, index) in item4List" :key="index">{{ item }}</li>
        </ol>
      </AccordionContent>
    </AccordionItem>
    <AccordionItem value="5" class="border-none">
      <AccordionTrigger
        class="!no-underline font-bold justify-start gap-2 text-start"
      >
        How to calculate Sonic rewards?
      </AccordionTrigger>
      <AccordionContent class="text-sonic-disabled border-none">
        The Sonic rewards for each referral are linked to the tier purchased by
        the person they referred. The higher the tier they purchase, the more
        $veToken rewards you receive.
      </AccordionContent>
    </AccordionItem>
  </Accordion>
</template>
