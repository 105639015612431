<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { canUseDOM } from "~/lib/dom";
import { wait } from "~/lib/timers";
import { useReferralRewards } from "~/services/queries/use-referral-rewards.ts";

const referralRewardsQuery = useReferralRewards();

const userHasPurchasedNodeBefore = computed(
  () => !!referralRewardsQuery?.data?.value?.rewards
);

const inviteCode = computed(
  () => referralRewardsQuery.data?.value?.invitation_code
);

const origin = computed(() => {
  if (!canUseDOM()) return "";
  const url = new URL(window.location.href);
  return url.origin;
});

const link = computed(() => `${origin.value}?invite=${inviteCode.value}`);

const { copy } = useClipboard();

const isCopyingLink = ref(false);
const isCopyingCode = ref(false);

async function copyLink() {
  isCopyingLink.value = true;
  copy(link.value);
  await wait(1000);
  isCopyingLink.value = false;
}

async function copyInviteCode() {
  if (!inviteCode.value) return;
  isCopyingCode.value = true;
  copy(inviteCode.value);
  await wait(1000);
  isCopyingCode.value = false;
}

// Copy animations
const initial = ref({
  scale: 0.6,
  opacity: 0,
  duration: 50,
});
const enter = ref({
  scale: 1,
  opacity: 1,
  duration: 1000,
});
</script>

<template>
  <div
    class="flex flex-col items-start justify-start gap-8 w-full font-sub border border-white/20 rounded-lg py-4 px-3 sm:px-6 sm:py-6"
  >
    <div class="flex items-center justify-start gap-2">
      <div class="font-sans text-base sm:text-2xl font-bold">
        Invite Your Friends
      </div>
      <MoleculesDiscountBadge>Up To 10% + 5% Cashback</MoleculesDiscountBadge>
    </div>

    <template v-if="userHasPurchasedNodeBefore">
      <div class="flex flex-col w-full">
        <!-- Invite link -->
        <div class="flex justify-between items-center w-full text-sm">
          <div class="flex-shrink-0">Invite Link:</div>

          <div
            class="flex items-center justify-end ml-auto w-full font-semibold"
          >
            <span class="max-w-[200px] truncate">
              {{ link }}
            </span>
            <Button
              size="icon"
              variant="link"
              class="text-sonic-disabled hover:text-white/60"
              @click="copyLink"
            >
              <template v-if="!isCopyingLink">
                <IconsCopy
                  class="w-6 h-6"
                  v-motion
                  :initial="initial"
                  :enter="enter"
                  :leave="initial"
                />
              </template>
              <template v-else>
                <IconsCheck
                  class="w-6 h-6 text-sonic-success"
                  v-motion
                  :initial="initial"
                  :enter="enter"
                  :leave="initial"
                />
              </template>
            </Button>
          </div>
        </div>

        <!-- Invite code -->
        <div class="flex justify-between items-center w-full text-sm">
          <div class="flex-shrink-0">Invite Code:</div>

          <div
            class="flex items-center justify-end ml-auto w-full font-semibold"
          >
            <span class="max-w-[180px] truncate">
              {{ inviteCode }}
            </span>
            <Button
              size="icon"
              variant="link"
              class="text-sonic-disabled hover:text-white/60"
              @click="copyInviteCode"
            >
              <template v-if="!isCopyingCode">
                <IconsCopy
                  class="w-6 h-6"
                  v-motion
                  :initial="initial"
                  :enter="enter"
                  :leave="initial"
                />
              </template>
              <template v-else>
                <IconsCheck
                  class="w-6 h-6 text-sonic-success"
                  v-motion
                  :initial="initial"
                  :enter="enter"
                  :leave="initial"
                />
              </template>
            </Button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="sm:text-lg text-sm">
        <NuxtLink
          class="text-sonic-lightblue hover:underline decoration-dashed"
          to="/"
          >Purchase</NuxtLink
        >
        a node to activate your invite code and receive cashback.
      </div>
    </template>
  </div>
</template>
