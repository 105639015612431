import { useQuery } from "@tanstack/vue-query";
import { api } from "../api";
import { keys, type ResponseType } from "../api.keys";
import type { SonicRewards } from "../types";
import { useWalletsStore } from "~/stores/wallets";

export async function getSonicRewards(wallet: string) {
  const { data } = await api.get<ResponseType<SonicRewards>>(
    "/referral/sonic-rewards",
    {
      params: {
        wallet,
      },
    }
  );

  return data;
}

export function useSonicRewards() {
  const wallet = useWalletsStore();
  const address = computed(() => wallet.wallet?.publicKey?.toBase58?.() || "");

  return useQuery({
    queryKey: keys.sonicRewards(unref(address)),
    queryFn: () => getSonicRewards(unref(address)),
    enabled: !!wallet.wallet && unref(address) !== "",
  });
}
