<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M21.1458 16.0418V12.5418H17.6458V10.7918H21.1458V7.2918H22.8958V10.7918H26.3958V12.5418H22.8958V16.0418H21.1458ZM10.5 13.6411C9.37708 13.6411 8.41585 13.2413 7.61629 12.4418C6.81654 11.642 6.41667 10.6807 6.41667 9.55776C6.41667 8.43484 6.81654 7.47361 7.61629 6.67405C8.41585 5.8743 9.37708 5.47443 10.5 5.47443C11.6229 5.47443 12.5842 5.8743 13.3837 6.67405C14.1835 7.47361 14.5833 8.43484 14.5833 9.55776C14.5833 10.6807 14.1835 11.642 13.3837 12.4418C12.5842 13.2413 11.6229 13.6411 10.5 13.6411ZM1.75 22.5258V19.9321C1.75 19.3608 1.90517 18.8317 2.2155 18.3448C2.52583 17.8579 2.94058 17.4836 3.45975 17.2219C4.61281 16.6566 5.77607 16.2326 6.94954 15.9499C8.12301 15.6672 9.3065 15.5258 10.5 15.5258C11.6935 15.5258 12.877 15.6672 14.0505 15.9499C15.2239 16.2326 16.3872 16.6566 17.5403 17.2219C18.0594 17.4836 18.4742 17.8579 18.7845 18.3448C19.0948 18.8317 19.25 19.3608 19.25 19.9321V22.5258H1.75ZM3.5 20.7758H17.5V19.9321C17.5 19.6958 17.4316 19.4771 17.2947 19.2758C17.1578 19.0747 16.972 18.9106 16.7373 18.7835C15.732 18.2884 14.7071 17.9133 13.6625 17.6582C12.6178 17.4033 11.5636 17.2758 10.5 17.2758C9.43639 17.2758 8.38221 17.4033 7.33746 17.6582C6.2929 17.9133 5.26799 18.2884 4.26271 18.7835C4.02801 18.9106 3.84222 19.0747 3.70533 19.2758C3.56844 19.4771 3.5 19.6958 3.5 19.9321V20.7758ZM10.5 11.8911C11.1417 11.8911 11.691 11.6626 12.1479 11.2057C12.6049 10.7487 12.8333 10.1994 12.8333 9.55776C12.8333 8.91609 12.6049 8.36679 12.1479 7.90984C11.691 7.4529 11.1417 7.22443 10.5 7.22443C9.85833 7.22443 9.30903 7.4529 8.85208 7.90984C8.39514 8.36679 8.16667 8.91609 8.16667 9.55776C8.16667 10.1994 8.39514 10.7487 8.85208 11.2057C9.30903 11.6626 9.85833 11.8911 10.5 11.8911Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
