<template>
  <svg
    width="345"
    height="142"
    viewBox="0 0 345 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M162.119 -36.5191C149.337 -27.7753 136.888 -17.4737 125.161 -5.74658C123.544 -4.12879 121.954 -2.49795 120.392 -0.855106C113.02 -3.07765 107.062 -3.73987 103.587 -3.4845C103.19 -3.45495 102.854 -3.42525 102.534 -3.38024C99.7771 -18.7634 99.7356 -35.2543 103.83 -52.4983C103.947 -52.498 104.063 -52.5056 104.178 -52.5131C104.288 -52.5203 104.397 -52.5274 104.502 -52.5272C115.755 -52.5446 126.938 -50.2566 138.354 -46.4716C146.15 -43.8624 154.082 -40.526 162.119 -36.5191ZM271.334 149.653C272.901 148.16 274.457 146.642 276 145.098C287.787 133.311 298.134 120.796 306.906 107.946C311.174 116.44 314.69 124.786 317.396 133.027C321.104 144.442 323.331 155.61 323.176 166.863C305.932 170.942 289.441 170.916 274.058 168.144C274.223 166.74 274.279 164.785 274.043 162.311C273.733 158.899 272.854 154.584 271.334 149.653Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M124.177 47.7945C132.824 38.7618 141.838 30.0616 151.073 21.8297L114.136 26.5131C120.272 18.6621 127.059 10.9753 134.469 3.56541C194.326 -56.2913 272.249 -75.4921 308.876 -39.5268C309.029 -39.3802 309.181 -39.2319 309.332 -39.0816C309.537 -38.8757 309.74 -38.6666 309.939 -38.4542C345.684 -1.75917 326.451 76.02 266.685 135.787C259.275 143.196 251.588 149.983 243.738 156.119L248.422 119.175C240.154 128.45 231.414 137.503 222.339 146.185C203.281 135.092 188.194 121.897 178.557 112.806C175.019 109.572 171.496 106.221 168.001 102.727C164.318 99.0433 160.779 95.317 157.37 91.591C148.194 81.8328 135.115 66.7524 124.177 47.7945ZM185.024 178.241C168.144 166.719 151.058 152.572 134.614 136.128C119.808 121.323 106.853 105.986 96.0094 90.7566C94.6713 88.8747 93.3654 86.9949 92.0921 85.1177C76.1571 105.906 63.39 126.664 54.8407 145.803L99.1088 126.029C55.9683 183.856 45.0377 225.212 45.0377 225.212C45.0377 225.212 86.2216 214.504 144.192 171.207L124.447 215.409C143.552 206.875 164.272 194.137 185.024 178.241Z"
      fill="currentColor"
    />
    <path
      d="M94.9351 8.22608C95.5274 13.0221 97.0854 19.2864 99.8214 26.4394C102.45 33.363 106.164 41.0986 110.842 49.2794C122.807 71.2057 137.83 88.5895 148.165 99.5788C151.763 103.512 155.499 107.446 159.387 111.334C163.076 115.023 166.795 118.56 170.53 121.974C181.534 132.354 199.27 147.806 221.762 160.032C224.637 161.673 227.466 163.222 230.218 164.619C237.082 168.102 243.533 170.775 249.233 172.653C254.307 174.345 258.798 175.303 262.449 175.77C281.21 180.062 301.734 180.587 323.34 174.595C322.943 180.9 321.723 187.28 319.28 193.488C316.837 199.697 313.065 205.703 308.07 210.669C302.16 216.593 294.841 220.759 287.411 223.122C280.881 225.213 274.256 226.051 267.706 226.081C256.224 226.113 244.827 223.763 233.182 219.901C215.73 214.07 197.627 204.664 179.196 192.296C160.781 179.912 142.099 164.55 124.142 146.594C108.513 130.964 94.8369 114.775 83.3903 98.6981C74.8091 86.629 67.4802 74.6394 61.5261 62.8824C57.0758 54.0608 53.3893 45.3785 50.5888 36.8359C46.4185 23.9992 44.066 11.4725 44.7823 -1.19923C45.1634 -7.51976 46.3841 -13.9298 48.8573 -20.1534C51.3 -26.3619 55.0717 -32.3532 60.0675 -37.3337C65.9771 -43.2585 73.2959 -47.4239 80.7261 -49.7872C85.8372 -51.4234 90.996 -52.2808 96.141 -52.5885C90.1487 -31.0128 90.6736 -10.5201 94.9504 8.22612L94.9351 8.22608Z"
      fill="currentColor"
    />
  </svg>
</template>
